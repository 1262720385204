import { take, put } from "redux-saga/effects";
import { INITIALIZE_APP } from "../actions";
import ApplicationConfig from "../utility/applicationConfig";
import { getNavigationMenu } from '../../letters/components/NavigationMenu/NavigationMenu.actions';
import {
  initializeAppInsights,
  getRolesMapping,
  getContactEmail,
  getClaims
} from "@ec-oem/ec.oem.oa3.ui.common/actions";
import history from "../utility/history";

export function* appStartSaga() {
  while (true) {
    yield take(INITIALIZE_APP);
    const config = yield ApplicationConfig.config;
    yield put(getClaims(config.common_api_path));
    yield put(initializeAppInsights(config.appinsights_InstrumentationKey, history));
    yield put(getNavigationMenu());
    yield put(getRolesMapping(config.PageRoleMapping, config.ComponentRoleMapping));
    yield put(getContactEmail(config.contactEmail));
  }
}
