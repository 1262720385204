
export const PageTitles = {
    SELECT_CONSOLE:"Select Console",
    APPLICATION:"Digital Contracting",
    ADD_TEMPLATE:"Add Template",
    EDIT_TEMPLATE:"Edit Template",
    SEARCH_TEMPLATE:"Templates",
    INITIATE_TEMPLATE:"Initiate Template",
    HISTORY_TEMPLATE:"History"
}

export const DCApplication = "Digital Contracting";
export const DcConstants = {
    DISABLED: 0,
    NOT_STARTED: 1,
    IN_PROGRESS: 2,
    COMPLETED: 3,
    VALIDATION_ERRROR: 4,
    SELECT_DC: "SELECT_DC",
    DOCX_EXTENSION: ".docx",
    PDF_EXTENSION: ".pdf",
    CANCEL_BTN: "Cancel",
    PROCEED_BTN: "Proceed",
}

export const CloseTemplateConstants = {
    CLOSE_CONFIMRATION: "Are you sure you want to Discontinue template?",
    CLOSE_TEMPLATE_TITLE: "Discontinue Template",
    CLOSED_STATUS : "Closed"
}

export const ComboBoxStyle ={
    OptionStyle:{
        optionText: {
        wordBreak: 'break-word',
        whiteSpace: 'normal', 
    }
},
   errorStyle:{
    errorMessage: {
   fontSize:"12px",
   }
  }
}