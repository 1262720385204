import { take, call, put, apply } from 'redux-saga/effects';
import { GET_NAVIGATION_MENU, setNavigationMenu } from './NavigationMenu.actions';
import { InvokeUrl } from '@ec-oem/ec.oem.oa3.ui.common/utility';
import ApplicationConfig from "../../../common/utility/applicationConfig";
import history from '../../../common/utility/history';

//Format the menu items to be displayed on the UI.
function getNavigationItemInMenuFormat(menuItems) {
    var updatedMenu = menuItems
        .map(function (item) {
            return { key: item.MenuItemName, name: item.DisplayText, onClick: () => history.push(item.RedirectURI) }
        });
    return updatedMenu;
}

// Navigation Saga
export function* getNavigationMenuSaga() {
    while (true) {
        try {
            yield take(GET_NAVIGATION_MENU);
            const config = yield ApplicationConfig.config;
            const responseC = yield call(InvokeUrl, { Config: { url: `${config.common_api_path}/menu`, method: 'GET' } });

            if (responseC.status === 200) {
                let navigationMenu = yield apply(responseC, responseC.json);
                navigationMenu = getNavigationItemInMenuFormat(navigationMenu);
                yield put(setNavigationMenu(navigationMenu));
            }
            else {
                console.log(responseC.status);
                yield put(setNavigationMenu([]));
            }
        } catch (error) {
            console.log("Failed to fetch NavigationMenuItems: " + error);
        }
    }
}
