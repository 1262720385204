/*
 *
 * SearchTemplate reducer
 *
 */

import { fromJS } from 'immutable';
import { createReducer } from '@ec-oem/ec.oem.oa3.ui.core/utility/createReducer';
import {  SET_SELECTED_TEMPLATES, SET_UPLOAD_TEMPLATE ,SET_UPLOAD_OEPRATION_COMPLETION_STATUS } from './SearchTemplate.actions';


export const templateList= createReducer(null, {
  [SET_SELECTED_TEMPLATES](state, {templateList}) {
    return fromJS(templateList);
  },
});

export const uploadRequest= createReducer(null, {
  [SET_UPLOAD_TEMPLATE](state, {uploadRequest}) {
    return fromJS(uploadRequest);
  },
});

export const uploadOperationCompleted=createReducer(null,{
  [SET_UPLOAD_OEPRATION_COMPLETION_STATUS](state,{uploadOperationCompleted}){
    return fromJS(uploadOperationCompleted)
  }
})
