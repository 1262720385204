import { take , put, call, apply } from 'redux-saga/effects';
import * as SearchTemplateActions from '../SearchTemplate/SearchTemplate.actions';
import ApplicationConfig from '../../../common/utility/applicationConfig';
import { InvokeUrl, guidGenerator } from '@ec-oem/ec.oem.oa3.ui.core/utility';
import { showAlert } from '@ec-oem/ec.oem.oa3.ui.core/components/Alert';
import { AlertsTypes } from '@ec-oem/ec.oem.oa3.ui.core/constants';
import { DcConstants } from '../../../common/constants/index';



const getSearchRequest = (searchText, pageSelection) => {
    let queryParams = ""; 
    queryParams = queryParams + (searchText ? "&SearchText=" + searchText : "");
    queryParams = queryParams + (pageSelection.page ? "&PageNo=" + pageSelection.page : "");
    queryParams = queryParams + (pageSelection.pageSize ? "&PageSize=" + pageSelection.pageSize : "");
    queryParams = queryParams + (pageSelection.sortBy ? "&SortColumn=" + pageSelection.sortBy : "");
    queryParams = queryParams + (pageSelection.sort ? "&SortDirection=" + pageSelection.sort : "");

    return queryParams;
}

function mapUploadTemplateDataToRequest(uploadFilter) {
        let request = {
        TemplateId: uploadFilter.inputData.TemplateId ? uploadFilter.inputData.TemplateId.value : "",
        uploadRequest: uploadFilter.UploadDetails.map((element)=>{
            return {
            TemplateName: element.TemplateName,
            FromRegion: element.From,
            TemplateDocument: element.FileContent
            }
        })}
      return request;
    }

    function mapItems(data){
        let templateList = data;
        templateList.Items.forEach((item) => {
            if( !item.AdditionalInformation || item.AdditionalInformation === "")
               item.disabledLink = true
               });
         templateList.ResultId = guidGenerator();
         return templateList ;     
    }
    
export function* getTemplatesSaga() {
    while (true) {   
         try {
            const { searchText , pageSelection }= yield take( SearchTemplateActions.GET_SELECTED_TEMPLATES);
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config :{url:`${config.web_api_path}/api/businesstool/businesstoolTemplates?`+ getSearchRequest(searchText,pageSelection ), method:'GET'}});
            if (response.status === 200) {
              const data = yield apply(response, response.json);
                const items=mapItems(data)
                        yield put(SearchTemplateActions.setSelectedTemplates(items));
                      } 
                   else {
                         yield put(SearchTemplateActions.setSelectedTemplates([]));
                   }
                }
                catch (error) {            
                    console.log("searchTemplateGetTemplatesSaga error: " + error);
                    yield put(showAlert({ type: AlertsTypes.ERROR, message: ["Failed to Search Templates."] }));
                }
          }
    }

// close the template
export function* closeTemplateSaga() {
      while (true) {   
           try {
                 const{ templateToBeClosed }= yield take( SearchTemplateActions.CLOSE_TEMPLATE);
                 var templateuniqueIndentifier = templateToBeClosed.TemplateUniqueIdentifier;
                 const config = yield ApplicationConfig.config;
                 const getResponse = yield call(InvokeUrl, { Config :{url:`${config.web_api_path}/api/businesstool/${templateuniqueIndentifier}/terminateBusinessToolTemplate`, method:'PUT'}});
                 if (getResponse.status === 200){
                   const searchText="";
                   const pageSelection = {page:"1",pageSize:"10",sort:"desc",sortBy:""};
                   const response = yield call(InvokeUrl, { Config :{url:`${config.web_api_path}/api/businesstool/businesstoolTemplates?`+ getSearchRequest(searchText,pageSelection ), method:'GET'}});
                   if (response.status === 200) {
                    const data = yield apply(response, response.json);
                    const items= mapItems(data)
                                yield put(SearchTemplateActions.setSelectedTemplates(items));
                              } 
                else {
                       yield put(SearchTemplateActions.setSelectedTemplates([]));
                         }

                      yield put(showAlert({ type: AlertsTypes.SUCCESS, message: ["Template Closed successfully."] }));
                    }        
                 }
           catch (error) {            
                    console.log("searchTemplateCloseTemplateSaga error: " + error);
                    yield put(showAlert({ type: AlertsTypes.ERROR, message: ["Failed to Close Template."] }));
             }
       }
}      

// Preview Template Saga
export function* previewTemplatesSaga() {
    while (true) {
        try {
            const { templateToBePreviewed } = yield take(SearchTemplateActions.PREVIEW_TEMPLATE);
            var templateUniqueIdentifier = templateToBePreviewed.TemplateUniqueIdentifier;
            const config = yield ApplicationConfig.config;

            yield put(showAlert({
                type: AlertsTypes.INFO,
                message: ["Download in progress. We'll let you know when it's done!"]
            }));
            var isPreview = true;
            const response = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/api/businesstool/${templateUniqueIdentifier}/${isPreview}/preivewTemplate`, method: 'GET' } });
            if (response.status === 200) {
                response.blob().then(data => {
                    var extension = isPreview ? DcConstants.DOCX_EXTENSION : DcConstants.PDF_EXTENSION;

                    var fileName = templateToBePreviewed.TemplateName + extension;
                    var result = new Blob([data]);
                    if (window.navigator.msSaveOrOpenBlob)
                        window.navigator.msSaveOrOpenBlob(result, fileName);
                    else {
                        var link = document.createElement('a');
                        link.id = fileName;
                        link.href = window.URL.createObjectURL(result);
                        link.download = fileName;
                        link.click();
                    }
                });
                yield put(showAlert({ type: AlertsTypes.SUCCESS, message: [" Template download complete ."] }));
            }
            else {
                yield put(showAlert({ type: AlertsTypes.ERROR, message: ["Failed to Download Template."] }));
            }
        }
        catch (error) {
            console.log("PreviewTemplateSaga error: " + error);
            yield put(showAlert({ type: AlertsTypes.ERROR, message: ["Failed to Download Template."] }));
        }
    }
}   

export function* uploadTemplateSaga() {
    while (true) {
        try {
            const { uploadFilter } = yield take(SearchTemplateActions.UPLOAD_TEMPLATE);
            let requestData = JSON.stringify(mapUploadTemplateDataToRequest(uploadFilter));
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/BusinessTool/uploadTemplate`, method: "POST", data: requestData}});
            if (response.status === 200) {   
                const data = yield apply(response, response.json); 
                if(data.Message && data.Message.Text && data.Message.Text!==""){
                    if(data.Message.Text === "Succeeded"){
                    yield put(SearchTemplateActions.uploadOperationCompletionStatus({status: true}));   
                    yield put(showAlert({ type: AlertsTypes.SUCCESS, message: ["Template uploaded successfully"] }));                     
                    } 
                else{
                yield put(showAlert({ type: AlertsTypes.ERROR, message: data.Message.Text }));
                }
            }
            }
            else {
            console.log(["Failed to upload template."]);
            yield put(showAlert({ type: AlertsTypes.ERROR, message: ["Failed to upload template."] }));
          }   
        } 
        catch (error) {
            console.log("uploadTemplateSaga error: " + error);
            yield put(showAlert({ type: AlertsTypes.ERROR,  message: ["Failed to upload template."] }));
        }
    }
}