/*
 *
 * History actions
 *
 */

import { makeActionCreator } from "@ec-oem/ec.oem.oa3.ui.core/utility/makeActionCreator";

export const GET_SELECTED_TEMPLATE_HISTORY = "HISTORY/GET_SELECTED_TEMPLATE_HISTORY";
export const getSelectedTemplateHistory = makeActionCreator(GET_SELECTED_TEMPLATE_HISTORY, false,"templateUniqueIdentifier","pageSelection");

export const SET_SELECTED_TEMPLATE_HISTORY = "HISTORY/SET_SELECTED_TEMPLATE_HISTORY";
export const setSelectedTemplateHistory = makeActionCreator(SET_SELECTED_TEMPLATE_HISTORY, false, "selectedTemplateHistory");

export const EXPORT_TEMPLATE_HISTORY = "HISTORY/EXPORT_TEMPLATE_HISTORY";
export const exportTemplateHistory = makeActionCreator(EXPORT_TEMPLATE_HISTORY, false, "template");
