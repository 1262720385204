import { take , put, call, apply } from 'redux-saga/effects';
import * as templateHistoryActions from './History.actions';
import ApplicationConfig from '../../../common/utility/applicationConfig';
import fileDownload  from 'react-file-download';
import { InvokeUrl } from '@ec-oem/ec.oem.oa3.ui.core/utility';
import { showAlert } from '@ec-oem/ec.oem.oa3.ui.core/components/Alert';
import { AlertsTypes } from '@ec-oem/ec.oem.oa3.ui.core/constants';
import ContactTypeConfig from '../InitiateTemplate/ContactTypeConfig.json'

const getSearchRequest = (pageSelection) => {
  let queryParams = ""; 
  queryParams = queryParams + (pageSelection.page ? "&pageNo=" + pageSelection.page : "");
  queryParams = queryParams + (pageSelection.pageSize ? "&pageSize=" + pageSelection.pageSize : "");

  return queryParams;
}


function mapItems(data){
var Items = data.Items.map((element)=>{
  return{
        GroupName: element.GroupName,
        HistoryDetails:element.HistoryDetails.map((item)=>{
       return{
           Company:item.CompanyName,
           EmailAccounts:item.ContactToEmailMapping.map(a => a.Value.join(', ')),
           ContactTypes: item.ContactToEmailMapping.map(a => 
           (ContactTypeConfig.filter(x => x.key === a.Key)).map(b => b.text)),
           SentBy:item.SentBy,
           SentDateTime:item.SentDateTime.split(" ")[0],
          }})}
       })
     return {groupedItems: Items, TotalCount:data.TotalCount};
}

export function* getTemplateHistorySaga() {
    while (true) {   
         try {
               const{ templateUniqueIdentifier, pageSelection }= yield take( templateHistoryActions.GET_SELECTED_TEMPLATE_HISTORY);
               const config = yield ApplicationConfig.config;
               const response = yield call(InvokeUrl, { Config :{url:`${config.web_api_path}/api/businesstool/${templateUniqueIdentifier}/history?`+ getSearchRequest(pageSelection), method:'GET'}});
               if (response.status === 200) {
                        const data = yield apply(response, response.json);
                        const items = mapItems(data);
                        yield put(templateHistoryActions.setSelectedTemplateHistory(items));
                  } 
                    else {
                       yield put(templateHistoryActions.setSelectedTemplateHistory([]));
                     }
                }
                catch (error) {            
                    console.log("templateHistorygetTemplateHistorySaga error: " + error);
                    yield put(showAlert({ type: AlertsTypes.ERROR, message: ["Failed to recieve History Details."] }));
                }
          }
    }   
 
 export function* exportTemplateHistorySaga() {
  while (true) {   
    try{
          const { template } = yield take( templateHistoryActions.EXPORT_TEMPLATE_HISTORY);
          const config = yield ApplicationConfig.config;
          yield put(showAlert({
            type: AlertsTypes.INFO,
            message: ["Download in progress. We'll let you know when it's done!"]
        }));
        const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/businesstool/${template.TemplateUniqueIdentifier}/exportTemplateHistory?templateName=${template.TemplateName}&templateId=${template.TemplateId}` , method:'GET'}});      
      if (response.status === 200) {
          response.blob().then(data => {
              fileDownload(data, `BusinessTool_Template_Report_${template.TemplateName}_${new Date().toDateString().split(" ").join("_")}.csv`);
          });
        yield put(showAlert({ type: AlertsTypes.SUCCESS, message: ["Report generated successfully"] }));
      }
      else
      {
        yield put(showAlert({ type: AlertsTypes.SUCCESS, message: ["Failed to generate report"] }));
      }    
  } 
   catch (error) {
        console.log("exportTemplateHistorySaga error: " + error);
        yield put(showAlert({ type: AlertsTypes.ERROR, message: ["Failed to generate Template History report"] }));
    }
   } 
 } 
