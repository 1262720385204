import { take , put , call, apply } from 'redux-saga/effects';
import * as initiateTemplateActions from './InitiateTemplate.actions';
import ApplicationConfig from '../../../common/utility/applicationConfig';
import { InvokeUrl } from '@ec-oem/ec.oem.oa3.ui.core/utility';
import { showAlert } from '@ec-oem/ec.oem.oa3.ui.core/components/Alert';
import { AlertsTypes } from '@ec-oem/ec.oem.oa3.ui.core/constants';

const getRequestTypeKeys = (requestType) => {
  let requestTypeArray = [];
  requestType && requestType.map(type => {
   requestTypeArray.push(type.key)
  })
  return requestTypeArray;
}

const getRequestTypes = (requestTypes) => {
  let queryParams = "";

  requestTypes && Object.keys(requestTypes).forEach((item) => {
    if(item!=="From" && item !=="Subject" && item!=="DynamicContent" && item!=="isBPOops")
    requestTypes[item].value.forEach(filter => {
        if (queryParams) {
          queryParams = queryParams + "&" + item + "=" + filter.key;
        }
        else{
          queryParams = item + "=" + filter.key;
        }
      });        
    });
    return queryParams;
  }

function mapInitiateTemplateDataToRequest(templateID , templateName, requestTypes) {
  let request = {
      TemplateId : templateID ,
      TemplateName : templateName ,
      From : requestTypes.From.value.text ,
      CustomerGroupCodes : requestTypes.CustomerGroupCodes.value ? getRequestTypeKeys(requestTypes.CustomerGroupCodes.value) : "",
      ContactTypeCodes : requestTypes.ContactTypeCodes.value ? getRequestTypeKeys(requestTypes.ContactTypeCodes.value) :"",
      CountryCodes : requestTypes.CountryCodes.value ? getRequestTypeKeys(requestTypes.CountryCodes.value) : "",
      SoldToIDs : requestTypes.SoldToIDs.value ? getRequestTypeKeys(requestTypes.SoldToIDs.value): "" ,
      Subject : requestTypes.Subject.value ,
      DynamicContent : requestTypes.DynamicContent ? requestTypes.DynamicContent.value : "",
      isBPOops : (requestTypes.isBPOops && requestTypes.isBPOops.value===true) ? requestTypes.isBPOops.value : false
  }
  return request;
}

export default function* initiateTemplateSaga() {
  
}

  export function* getCountriesSaga() {
    while (true) {   
      try {
        yield take(initiateTemplateActions.GET_COUNTRIES);
        const config = yield ApplicationConfig.config;
        const response = yield call(InvokeUrl, { Config :{url:`${config.web_api_path}/api/Settings/CountryData`, method:'GET'}});
        if (response.status === 200) {
          const data = yield apply(response, response.json);
          let responseData=JSON.parse(data);
          var countryDescriptions =  responseData.countries.map((d) => {
              return { key: d.value, text: d.display };
          });
          yield put(initiateTemplateActions.setCountries(countryDescriptions));
          }
           else {
            console.log(["Failed to fetch Countries."]);
             yield put(showAlert({ type: AlertsTypes.ERROR, message: ["Failed to fetch Countries."] }));
          } 
        }
        catch (error) {            
          console.log("getCountriesSaga error: " + error);
          yield put(showAlert({ type: AlertsTypes.ERROR,  message: ["Failed to fetch Countries."] }));
        }
    }
  }

  export function* getCustomerGroupSaga() {
    while (true) {   
      try {
        yield take(initiateTemplateActions.GET_CUSTOMER_GROUPS);
        const config = yield ApplicationConfig.config;
        const response = yield call(InvokeUrl, { Config :{url:`${config.web_api_path}/api/Settings/CustomerGroupData`, method:'GET'}});
          if (response.status === 200) {
            const data = yield apply(response, response.json);
            let responseData=JSON.parse(data);
            var cutomergroupDescriptions = responseData.CustomerGroupCodes.map((d) => {
                return { key: d.GroupCode, text: d.FriendlyName };
            });
            yield put(initiateTemplateActions.setCustomerGroups(cutomergroupDescriptions));
          }
          else {
            console.log(["Failed to fetch Customer groups."]);
             yield put(showAlert({ type: AlertsTypes.ERROR, message: ["Failed to fetch Customer groups."] }));
          } 
          }
          catch (error) {            
              console.log("getCustomerGroupSaga error: " + error);
              yield put(showAlert({ type: AlertsTypes.ERROR,  message: ["Failed to fetch Customer groups."] }));
          }
      }
  }

  export function* soldToListSaga() {
    while (true) {   
      try {
          yield take(initiateTemplateActions.GET_SOLDTO_LIST);
          const config = yield ApplicationConfig.config;
          const response = yield call(InvokeUrl, { Config :{url:`${config.web_api_path}/api/user/companies`, method:'GET'}});
          if (response.status === 200) {
            const data = yield apply(response, response.json);
            var soldToData = data.map((d) => {
                return {
                    key: d.CompanyID.toString(),
                    displayText: d.CompanyName + "(" + d.CompanyNumber + ")"
                };
            });
            yield put(initiateTemplateActions.setSoldToList(soldToData));
          } 
          else {
            console.log(["Failed to fetch soldTo Orgs."]);
             yield put(showAlert({ type: AlertsTypes.ERROR, message: ["Failed to fetch Companies."] }));
          }
      } catch (error) {
          console.log("soldToListSaga error:" + error);
          yield put(showAlert({ type: AlertsTypes.ERROR, message: ["Failed to fetch Companies."] }));
      }
    }
  }
  
  export function* getToListSaga() {
    while (true) {   
        try {
          const { templateUniqueIdentifier , requestTypes } = yield take(initiateTemplateActions.GET_TO_LIST);
          const config = yield ApplicationConfig.config;
            const response =yield call(InvokeUrl, { Config :{url:`${config.web_api_path}/api/BusinessTool/${templateUniqueIdentifier}/generateToList?`+getRequestTypes(requestTypes), method:'GET'}});
              if (response.status === 200) {
              const data = yield apply(response, response.json);
              yield put(initiateTemplateActions.setToList({ Items: data }));
                }
              else {
                console.log(["Failed to fetch To List."]);
               yield put(showAlert({ type: AlertsTypes.ERROR, message: ["Failed to fetch To List."] }));
           }  
        }
        catch (error) {            
          console.log("getToListSaga error: " + error);
          yield put(showAlert({ type: AlertsTypes.ERROR,  message: ["Failed to fetch To List."] }));
        }
    }
  }

  export function* saveInitiateTemplateSaga() {
    while (true) {
        try {
          const {  templateUniqueIdentifier , templateID , templateName , initiateRequest } = yield take(initiateTemplateActions.SAVE_INITIATE_TEMPLATE);
          let requestData = JSON.stringify(mapInitiateTemplateDataToRequest(templateID , templateName , initiateRequest));
          const config = yield ApplicationConfig.config;
          const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/BusinessTool/${templateUniqueIdentifier}/createMail`, method: "POST", data: requestData}});
          if (response.status === 200) {     
            yield put(initiateTemplateActions.saveInitiateOperationCompletionStatus({status: true}));
            yield put(initiateTemplateActions.setToList(null));   
            yield put(showAlert({ type: AlertsTypes.SUCCESS, message: ["Notice Letter sent successfully"] }));                     
          } 
          else {
            console.log(["Failed to send Notice Letter."]);
            yield put(showAlert({ type: AlertsTypes.ERROR, message: ["Failed to send Notice Letter."] }));
          }  
        } 
        catch (error) {
          console.log("saveInitiateTemplateSaga error: " + error);
          yield put(showAlert({ type: AlertsTypes.ERROR,  message: ["Failed to send Notice Letter."] }));
        }
    }
  }
    