/*
 *
 * InitiateTemplate reducer
 *
 */

import { fromJS } from 'immutable';
import { createReducer } from '@ec-oem/ec.oem.oa3.ui.core/utility/createReducer';
import * as initiateTemplateActions from './InitiateTemplate.actions';

export const defaultData = createReducer(null, {
  [initiateTemplateActions.DEFAULT_ACTION](state, { defaultValue }) {
    return fromJS(defaultValue);
  },
});

export const countriesList= createReducer(null, {
  [initiateTemplateActions.SET_COUNTRIES](state, {countriesList}) {
    return fromJS(countriesList);
  },
});

export const customerGroups= createReducer(null, {
  [initiateTemplateActions.SET_CUSTOMER_GROUPS](state, {customerGroups}) {
    return fromJS(customerGroups);
  },
});

export const soldToList = createReducer(null, {    
  [initiateTemplateActions.SET_SOLDTO_LIST](state, { soldToList }) {
      return fromJS(soldToList);
  }
});

export const toList = createReducer(null, {    
  [initiateTemplateActions.SET_TO_LIST](state, { toList }) {
      return fromJS(toList);
  }
});

export const saveInitiateOperationCompleted=createReducer(null,{
  [initiateTemplateActions.SET_SAVE_INITIATE_OEPRATION_COMPLETION_STATUS](state,{saveInitiateOperationCompleted}){
    return fromJS(saveInitiateOperationCompleted)
  }
})