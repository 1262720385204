/*
 *
 * AddTemplate reducer
 *
 */

import { fromJS } from 'immutable';
import { createReducer } from '@ec-oem/ec.oem.oa3.ui.core/utility/createReducer';
import * as addTemplateActions from './AddTemplate.actions';


export const defaultData = createReducer(null, {
  [addTemplateActions.DEFAULT_ACTION](state, { defaultValue }) {
    return fromJS(defaultValue);
  },
});

export const saveOperationCompleted=createReducer(null,{
  [addTemplateActions.SET_SAVE_OEPRATION_COMPLETION_STATUS](state,{saveOperationCompleted}){
    return fromJS(saveOperationCompleted)
  }
})

