/*
 *
 * NavigationMenu reducer
 *
 */

import { fromJS } from 'immutable';
import { createReducer } from '@ec-oem/ec.oem.oa3.ui.core/utility/createReducer';
import { SET_NAVIGATION_MENU } from './NavigationMenu.actions';

export const navigationMenu = createReducer(null, {
    [SET_NAVIGATION_MENU](state, { navigationMenu }) {
        return fromJS(navigationMenu);
    },
});
