/*
 *
 * History reducer
 *
 */

import { fromJS } from 'immutable';
import { createReducer } from '@ec-oem/ec.oem.oa3.ui.core/utility/createReducer';
import { SET_SELECTED_TEMPLATE_HISTORY } from './History.actions';

export const selectedTemplateHistory = createReducer(null, {
  [SET_SELECTED_TEMPLATE_HISTORY](state, { selectedTemplateHistory }) {
    return fromJS(selectedTemplateHistory);
  },
});
