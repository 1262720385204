/*
 *
 * NavigationMenu actions
 *
 */

import { makeActionCreator } from "@ec-oem/ec.oem.oa3.ui.core/utility/makeActionCreator";


export const GET_NAVIGATION_MENU = "letters/components/NavigationMenu/GET_NAVIGATION_MENU";
export const getNavigationMenu = makeActionCreator(GET_NAVIGATION_MENU, false);

export const SET_NAVIGATION_MENU = "letters/components/NavigationMenu/SET_NAVIGATION_MENU";
export const setNavigationMenu = makeActionCreator(SET_NAVIGATION_MENU, false, "navigationMenu");

