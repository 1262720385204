/*
 *
 * SearchTemplate actions
 *
 */

import { makeActionCreator } from "@ec-oem/ec.oem.oa3.ui.core/utility/makeActionCreator";


export const GET_SELECTED_TEMPLATES = "SEARCH_TEMPLATE/GET_SELECTED_TEMPLATES";
export const getSelectedTemplates= makeActionCreator(GET_SELECTED_TEMPLATES, false,"pageSelection","searchText");

export const SET_SELECTED_TEMPLATES = "SEARCH_TEMPLATE/SET_SELECTED_TEMPLATES";
export const setSelectedTemplates= makeActionCreator(SET_SELECTED_TEMPLATES, false,"templateList");

export const CLOSE_TEMPLATE = "SEARCH_TEMPLATE/CLOSE_TEMPLATE";
export const closeTemplate= makeActionCreator(CLOSE_TEMPLATE, false,"templateToBeClosed");

export const PREVIEW_TEMPLATE = "SEARCH_TEMPLATE/PREVIEW_TEMPLATE";
export const previewTemplate = makeActionCreator(PREVIEW_TEMPLATE, false, "templateToBePreviewed");

export const UPLOAD_TEMPLATE = "SEARCH_TEMPLATE/UPLOAD_TEMPLATE";
export const uploadTemplate = makeActionCreator(UPLOAD_TEMPLATE, false, "uploadFilter");

export const SET_UPLOAD_TEMPLATE = "SEARCH_TEMPLATE/SET_UPLOAD_TEMPLATE";
export const setUploadTemplate = makeActionCreator(SET_UPLOAD_TEMPLATE, false, "uploadRequest");

export const SET_UPLOAD_OEPRATION_COMPLETION_STATUS = "SEARCH_TEMPLATE/SET_UPLOAD_OEPRATION_COMPLETION_STATUS";
export const uploadOperationCompletionStatus = makeActionCreator(SET_UPLOAD_OEPRATION_COMPLETION_STATUS, false, "uploadOperationCompleted");
