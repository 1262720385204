/*
 *
 * AddTemplate actions
 *
 */

import { makeActionCreator } from "@ec-oem/ec.oem.oa3.ui.core/utility/makeActionCreator";

export const DEFAULT_ACTION = "ADD_TEMPLATE/DEFAULT_ACTION";
export const defaultAction = makeActionCreator(DEFAULT_ACTION, false, "defaultValue");

export const SAVE_TEMPLATE = "ADD_TEMPLATE/SAVE_TEMPLATE";
export const saveTemplate = makeActionCreator(SAVE_TEMPLATE, false, "templateRequest");

export const SET_SAVE_OEPRATION_COMPLETION_STATUS = "ADD_TEMPLATE/SET_SAVE_OEPRATION_COMPLETION_STATUS";
export const saveOperationCompletionStatus = makeActionCreator(SET_SAVE_OEPRATION_COMPLETION_STATUS, false, "saveOperationCompleted");