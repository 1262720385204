/*
 *
 * InitiateTemplate actions
 *
 */

import { makeActionCreator } from "@ec-oem/ec.oem.oa3.ui.core/utility/makeActionCreator";

export const DEFAULT_ACTION = "INITIATE_TEMPLATE/DEFAULT_ACTION";
export const defaultAction = makeActionCreator(DEFAULT_ACTION, false, "defaultValue");

export const GET_COUNTRIES = "INITIATE_TEMPLATE/GET_COUNTRIES";
export const getCountries = makeActionCreator(GET_COUNTRIES, false);
export const SET_COUNTRIES = "INITIATE_TEMPLATE/SET_COUNTRIES";
export const setCountries= makeActionCreator(SET_COUNTRIES, false,"countriesList");

export const GET_CUSTOMER_GROUPS = "INITIATE_TEMPLATE/GET_CUSTOMER_GROUPS";
export const getCustomerGroups = makeActionCreator(GET_CUSTOMER_GROUPS, false);
export const SET_CUSTOMER_GROUPS = "INITIATE_TEMPLATE/SET_CUSTOMER_GROUPS";
export const setCustomerGroups= makeActionCreator(SET_CUSTOMER_GROUPS, false,"customerGroups");

export const GET_SOLDTO_LIST ="INITIATE_TEMPLATE/GET_SOLDTO_LIST";
export const getSoldToList = makeActionCreator(GET_SOLDTO_LIST, false);
export const SET_SOLDTO_LIST = "INITIATE_TEMPLATE/SET_SOLDTO_LIST";
export const setSoldToList = makeActionCreator(SET_SOLDTO_LIST, false, "soldToList");

export const GET_TO_LIST ="INITIATE_TEMPLATE/GET_TO_LIST";
export const getToList = makeActionCreator(GET_TO_LIST, false, "templateUniqueIdentifier" , "requestTypes");
export const SET_TO_LIST = "INITIATE_TEMPLATE/SET_TO_LIST";
export const setToList = makeActionCreator(SET_TO_LIST, false, "toList");

export const SAVE_INITIATE_TEMPLATE = "INITIATE_TEMPLATE/SAVE_INITIATE_TEMPLATE";
export const saveInitiateTemplate = makeActionCreator(SAVE_INITIATE_TEMPLATE, false, "templateUniqueIdentifier", "templateID" , "templateName" ,"initiateRequest");

export const SET_SAVE_INITIATE_OEPRATION_COMPLETION_STATUS = "INITIATE_TEMPLATE/SET_SAVE_INITIATE_OEPRATION_COMPLETION_STATUS";
export const saveInitiateOperationCompletionStatus = makeActionCreator(SET_SAVE_INITIATE_OEPRATION_COMPLETION_STATUS, false, "saveInitiateOperationCompleted");
