import { call, put, take, apply } from 'redux-saga/effects';
import { InvokeUrl } from '@ec-oem/ec.oem.oa3.ui.core/utility';
import * as addTemplateActions from './AddTemplate.actions';
import ApplicationConfig from '../../../../app/common/utility/applicationConfig';
import { showAlert } from '@ec-oem/ec.oem.oa3.ui.core/components/Alert';
import { AlertsTypes } from '@ec-oem/ec.oem.oa3.ui.core/constants';

 function mapTemplateDataToRequest(templateRequest) {
    let request = {
        TemplateUniqueIdentifier: templateRequest.UniqueTemplateId.value ? templateRequest.UniqueTemplateId.value : "",
        TemplateId: templateRequest.TemplateId.value,
        TemplateName: templateRequest.TemplateName.value,
        TemplateType: templateRequest.TemplateType.text,
        AdditionalInformation: templateRequest.AdditionalInfo ? templateRequest.AdditionalInfo.value : ""
    }
    if (templateRequest.isEdit === true ){
          request.id = templateRequest.id.value;
          request.CreatedDate = templateRequest.CreatedDate.value;
          request.Status = templateRequest.Status.value;
          request.DocumentType = templateRequest.DocumentType.value;
     }
    return request;
}


export function* saveTemplateSaga() {
    while (true) {
    try {
        const { templateRequest } = yield take(addTemplateActions.SAVE_TEMPLATE);
        let requestData = JSON.stringify(mapTemplateDataToRequest(templateRequest));
        const config = yield ApplicationConfig.config;
        if (templateRequest.isEdit == false){
        const getResponse = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/BusinessTool/isDuplicate?templateName=${templateRequest.TemplateName.value}&templateId=${templateRequest.TemplateId.value}`, method: "GET"}});
        if (getResponse.status === 200) { 
            const result = yield apply(getResponse, getResponse.json); 
            if(result.Message && result.Message.Text && result.Message.Text !=="")
            {
            if(result.Message.Text === "Succeeded"){
            const response = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/BusinessTool/addTemplate`, method: "POST", data: requestData}});
                if (response.status === 200) {
                    yield put(addTemplateActions.saveOperationCompletionStatus({status: true})); 
                    yield put(showAlert({ type: AlertsTypes.SUCCESS, message: ["Template saved successfully"] })); 
                } 
            }
            else{ 
            yield put(showAlert({ type: AlertsTypes.ERROR, message: result.Message.Text }));
             }
            }
          }
        }
        else if(templateRequest.isEdit == true){
            const response1 = yield call(InvokeUrl, { Config : {url:`${config.web_api_path}/api/BusinessTool/addTemplate`, method: "POST", data: requestData}});
            if (response1.status === 200) {
                yield put(addTemplateActions.saveOperationCompletionStatus({status: true})); 
                yield put(showAlert({ type: AlertsTypes.SUCCESS, message: ["Template saved successfully"] })); 
            } 
        }
        else{
        console.log(["Failed to save template."]);
        yield put(showAlert({ type: AlertsTypes.ERROR, message: ["Failed to save template."] }));
        } 
    } 
    catch (error) {
        console.log("saveTemplateSaga error: " + error);
        yield put(showAlert({ type: AlertsTypes.ERROR, message: ["Failed to save template."] }));
        }
    }
}